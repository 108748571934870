import { ShopifyProduct, ShopifyProductVariant } from './storefront-api-client/types/custom.types';
import { Cart, ExternalVideo, Image, MoneyV2 } from './storefront-api-client/types/storefront.types';

export type ProductLike = {
  variants: ShopifyProductVariant[]
}
export const flattenProductVariants = (products: ProductLike[], onlyAvailableVariants?: boolean) => (
  products.reduce((prev, current) => {
    if (onlyAvailableVariants) {
      current.variants = current.variants.filter(v => v.availableForSale);
    }
    if (current === null) return prev;
    return [...prev, ...current.variants]
  }, [] as ShopifyProductVariant[])
)

export const resolveProduct = ({ node }: any) => {
  return {
    ...node,
    images: mapEdgeNodes<Image>(node.images),
    variants: mapEdgeNodes<ShopifyProductVariant>(node.variants),
    media: mapEdgeNodes(node.media as any) as any[],
  }
}

type ResolveProductsArgs = { 
  edges: { 
    node: ShopifyProduct 
  }[]
}
export const resolveProducts = (productEdges: ResolveProductsArgs) => {
  return productEdges.edges.map(productEdge => resolveProduct(productEdge))
}

export const resolveCart = (cart: Cart) => {
  return {
    ...cart,
    lines: cart.lines.nodes.map(line => (
      {
        ...line,
        merchandise: {
          ...line.merchandise,
          product: {
            ...line.merchandise.product,
            images: mapEdgeNodes(line.merchandise.product.images),
          }
        }
      }
    ))
  }
}


function mapEdgeNodes<T, K = void>(edgeConnection: { edges: { node: T }[]}): K|T[] {
  return edgeConnection.edges.map(({ node }) => node)
}