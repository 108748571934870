import React, {
  FC,
  useState,
  useEffect,
  useContext,
  createContext,
} from "react"
import Cookies from "js-cookie"
import { createCart, fetchCart, cartAddLineItems, cartUpdateLineItems, cartRemoveLineItems } from "../lib/shopify"
import { useCookie } from "../hooks"
import { ShopifyCart } from "../lib/shopify/storefront-api-client/types/custom.types"
import { BaseCartLine } from "../lib/shopify/storefront-api-client/types/storefront.types"

export const initialCheckoutValues = {
  cartQuantity: 0,
  addVariantToCart: (_: string, __: string) => {},
  setIsCartOpen: (_: boolean) => {},
  checkout: { lines: [] } as unknown as ShopifyCart,
  updateQuantityInCart: (_: string, __: string) => {},
  removeLineItemInCart: (_: string) => {},
}

export const CheckoutContext = createContext(initialCheckoutValues)

export const CheckoutProvider: FC = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [checkout, setCheckout] = useState({ lines: [] } as unknown as ShopifyCart)
  const [cartId, setCartId] = useCookie({ key: "proCartId", expires: 1 })
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false)

  const createCheckout = async () => {
    setIsCheckoutLoading(true)
    try {
      const checkoutCart: ShopifyCart = await createCart();
      setCartId(checkoutCart.id)
      setCheckout(checkoutCart)
      setIsCheckoutLoading(false)
    } catch (e) {
      console.log(e)
      setIsCheckoutLoading(false)
    }
  }

  const getCheckout = async () => {
    if (cartId) {
      try {
        const checkoutCart = await fetchCart(cartId)
        if (!checkoutCart) {
          // If checkout was complete then reset cart cookies and make new checkout
          Cookies.remove("proCartId")
          Cookies.remove("proDiscountId")
          return createCheckout()
        }
        return setCheckout(checkoutCart)
      } catch (e) {
        console.log(e)
      }
    }
    return createCheckout()
  }

  useEffect(() => {
    getCheckout()
  }, [])

  const addVariantToCart = async (variantId: string, quantity: string) => {
    try {
      setIsCheckoutLoading(true)
      if (!isCartOpen) {
        setIsCartOpen(true)
      }
      const lineItemsToAdd = [{ merchandiseId: variantId , quantity: parseInt(quantity, 10) }];
      const result = await cartAddLineItems(checkout.id, lineItemsToAdd);
      setCheckout(result)
      setIsCheckoutLoading(false)
    } catch (error) {
      console.log(error)
      setIsCheckoutLoading(false)
    }
  }

  const updateQuantityInCart = async (lineItemId: string, quantity: string) => {
    if (parseInt(quantity, 10) <= 0) {
      return removeLineItemInCart(lineItemId)
    }

    setIsCheckoutLoading(true)
    try {
      const result = await cartUpdateLineItems(checkout.id, [
        { id: lineItemId, quantity: parseInt(quantity, 10) },
      ])
      setCheckout(result)
      setIsCheckoutLoading(false)
    } catch (e) {
      console.log(e)
      setIsCheckoutLoading(false)
    }
  }

  const removeLineItemInCart = async (lineItemId: string) => {
    setIsCheckoutLoading(true)
    try {
      const result = await cartRemoveLineItems(checkout.id, [
        lineItemId,
      ])
      setCheckout(result)
      setIsCheckoutLoading(false)
    } catch (e) {
      console.log(e)
      setIsCheckoutLoading(false)
    }
  }

  const cartQuantity = checkout.lines.length
    ? checkout.lines
        .map((lineItem: BaseCartLine) => lineItem.quantity)
        .reduce((accumulator, quantity) => accumulator + quantity)
    : 0

  return (
    <CheckoutContext.Provider
      value={{
        cartQuantity,
        isCheckoutLoading,
        setIsCheckoutLoading,
        addVariantToCart,
        setIsCartOpen,
        checkout,
        isCartOpen,
        updateQuantityInCart,
        removeLineItemInCart,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export const useCheckout = () => useContext(CheckoutContext)
