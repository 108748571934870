import { storefrontAPIClient } from "../client";
import { ShopifyCart } from "../types/custom.types";
import { Cart, CartLineInput, CartUserError } from "../types/storefront.types";
import { CART_LINE_ITEMS_ADD_MUTATION } from "../graphql";
import { resolveCart } from "../../transformations";

type CartResponse = { 
  cartLinesAdd: {
    cart: Cart
    userErrors?: CartUserError[]
  }
}
export const cartAddLineItems = async (cartId: string, lines: CartLineInput[]): Promise<ShopifyCart> => {
  const { data, errors } = await storefrontAPIClient.request<CartResponse>(
    CART_LINE_ITEMS_ADD_MUTATION, 
    { 
      variables: {
        cartId,
        lines
      }
    }
  );

  if (data?.cartLinesAdd.userErrors?.length) {
    throw new Error(data.cartLinesAdd.userErrors.map(e => e.message).join(', '));
  }

  if (errors) throw new Error(errors.message);

  if (!data?.cartLinesAdd) throw new Error('Could not add lines to cart!');

  return resolveCart(data.cartLinesAdd.cart);
}