import React from "react"
import styled from "styled-components"
import { Flex } from "../../styles"
import { useCheckout } from "../../global"
import Icon from "../Icon"
import { BaseCartLine } from "../../lib/shopify/storefront-api-client/types/storefront.types"

type Props = {
  lineItem: BaseCartLine
}

const CartItem = ({ lineItem }: Props) => {
  const { removeLineItemInCart, updateQuantityInCart } = useCheckout()

  const price = `$${(lineItem.quantity * lineItem.merchandise.price.amount).toFixed(2)}`

  return (
    <CartLineItem data-testid="cart-lineitem">
      <Flex css="padding-bottom: 1rem" scalign="flex-start">
        <ImgWrapper css="display: flex; align-items: center;">
          {lineItem.merchandise.image && (
            <img src={lineItem.merchandise.image.url} alt={`${lineItem.merchandise.product.title}`} />
          )}
        </ImgWrapper>
        <div css="flex: 2; display: flex; flex-direction: column; justify-content: space-between;">
          <Flex css="margin: 0">
            {lineItem.merchandise.product.title !== "Default Title" && (
              <div>
                <p className="subtext" css="display: block">
                  {lineItem.merchandise.product.title}
                </p>
                <p className="subtext" css="display: block; width: 100%">
                  {lineItem.merchandise.sku}
                </p>
              </div>
            )}
            <button
              css="height: 22px"
              onClick={() => {
                removeLineItemInCart(lineItem.id)
              }}
            >
              <Icon name="remove" color="var(--primaryBlue)" />
            </button>
          </Flex>
          <p css="margin: 1rem 0; color: var(--primaryColor); fontSize: var(--largeFontSize)">
            {lineItem.merchandise.product.title}
          </p>
        </div>
      </Flex>

      <Flex css="border-top: 1px solid var(--lightestGray); padding: 3rem 0">
        <Quantity>
          <button
            onClick={() =>
              updateQuantityInCart(lineItem.id, lineItem.quantity - 1)
            }
          >
            <Icon name="subtract" color="var(--primaryColor)" />
          </button>
          <span className="quantity">{lineItem.quantity}</span>
          <button
            onClick={() =>
              updateQuantityInCart(lineItem.id, lineItem.quantity + 1)
            }
          >
            <Icon name="add" color="var(--primaryColor)" />
          </button>
        </Quantity>

        <p css="color: var(--primaryColor); margin: 0; font-size: var(--largeFontSize)">
          {price}
        </p>
      </Flex>
    </CartLineItem>
  )
}

export default CartItem

const CartLineItem = styled.div`
  background: var(--lightesterGray);
  padding: 2rem 2rem 0;
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`

const ImgWrapper = styled.div`
  width: 100px;
  flex: 1;
  img {
    width: inherit;
    display: block;
    height: auto;
  }
`

const Quantity = styled.div`
  * {
    display: inline-block;
    vertical-align: middle;
  }
  .quantity {
    border-radius: 3px;
    border: 1px solid var(--lighterGray);
    margin: 0 1rem;
    padding: 0 1.5rem;
    pointer-events: none;
  }
`
